<template>
  <div>
    <div v-if="!entityDataFetching && !entity" class="current-item-wrapper">
      <div class="container">
        <MsgEntityNotFound name="Mini Cultivist Event" />
      </div>
    </div>

    <div v-else-if="entity">
      <template v-if="entity.type === 'Event'">
        <div class="current-item-wrapper">
          <EntityPageHeader
            :image="mediaUrl"
            :title="entity.title"
            title-before="Cultivist"
            :title-after="destinationTitle"
          />
          <div class="container">
            <div class="data-details__actions d-flex justify-content-center">
              <LikeToAttendAction :event="entity" @show-museum-modal="isMuseumModal = true" />
              <AddToFavoriteAction
                v-if="entity.id"
                :event="entity"
                :is-favorite="isFavoriteState"
                @click="handleFavoriteAction"
              />
              <FindOnMapAction @click="showFindOnMap = true" />
              <AddReviewAction @click="setDestination(entity.destination.title)" />
            </div>
            <DataItemInfo :item="entity" />
          </div>
        </div>

        <EntityDetailsMap :event="entity" :show-details="false" @click-map-area="showFindOnMap = true" />
        <AppRelatedSection v-if="relatedRecords.length" :items="relatedRecords" :related-to-title="entity.title" />
      </template>

      <div v-else class="page-wrap stories_wrp" :class="{ 'pb-0': relatedRecords.length }">
        <div class="data-details main-content art-space mobile-style-img">
          <div class="data-details__main-data">
            <div class="data-details__top" :class="{ 'header-without-media': !mediaUrl }">
              <h1 class="data-details__title">{{ entity.title }}</h1>
              <div class="d-flex justify-content-center mt-3">
                <div v-if="publishedAt" class="data-details__cat text-center text-uppercase">{{ publishedAt }}</div>
              </div>
              <div class="img" :style="`background-image: url(${mediaUrl})`" />
            </div>
            <div v-if="entity.description" class="story-content">
              <div class="container story-content-part story-content-part--default" v-html="entity.description" />
            </div>
            <AppRelatedSection v-if="relatedRecords.length" :items="relatedRecords" :related-to-title="entity.title" />
          </div>
        </div>
      </div>
    </div>

    <FindOnMap
      v-if="showFindOnMap"
      :item="entity"
      :latitude="entityLatitude"
      :longitude="entityLongitude"
      :zoom="13"
      @close="showFindOnMap = false"
    />
    <UpgradeFromEnthusiastMsgModal v-model="isMuseumModal" />
  </div>
</template>

<script>
import ImageHandler from '@/helpers/ImageHandler';
import { prepareVariablesForSingleEntityQuery, redirectToSingleEntityRoute } from '@/helpers/graphqlHelper';
import { formatDate } from '@/helpers/dateTimeHelper';

import miniCultivistQuery from '@/graphql/miniCultivist/MiniCultivist.single.query.gql';
import addToUserFavoritesMutation from '@/graphql/me/favorite/AddToUserFavorites.mutation.gql';
import deleteFromUserFavoritesMutation from '@/graphql/me/favorite/DeleteFromUserFavorites.mutation.gql';

import EntityPageHeader from '@/components/EntityPageHeader.vue';
import LikeToAttendAction from '@/components/partials/actions/LikeToAttendAction';
import AddToFavoriteAction from '@/components/partials/actions/AddToFavoriteAction';
import FindOnMapAction from '@/components/partials/actions/FindOnMapAction';
import AddReviewAction from '@/components/partials/actions/AddReviewAction';
import AppRelatedSection from '@/components/partials/AppRelatedSection';
import DataItemInfo from '@/components/partials/DataItemInfo';
import MsgEntityNotFound from '@/components/MsgEntityNotFound.vue';
import EntityDetailsMap from '@/components/EntityDetailsMap.vue';

export default {
  name: 'MiniCultivistSinglePage',
  components: {
    EntityDetailsMap,
    EntityPageHeader,
    MsgEntityNotFound,
    LikeToAttendAction,
    AddToFavoriteAction,
    FindOnMapAction,
    AddReviewAction,
    AppRelatedSection,
    DataItemInfo,
    FindOnMap: () => import('@/components/partials/FindOnMap'),
    UpgradeFromEnthusiastMsgModal: () => import('@/components/modals/UpgradeFromEnthusiastMsgModal'),
  },
  metaInfo() {
    return {
      title: this.entity?.title || 'Mini Cultivist event',
    };
  },
  data() {
    return {
      entity: null,
      entityDataFetching: true,
      isFavoriteState: false,
      isMuseumModal: false,
      relatedRecords: [],
      showFindOnMap: false,
    };
  },
  computed: {
    destinationTitle() {
      if (!this.entity.destination || !this.entity.destination.title) {
        return '';
      }

      let title = this.entity.destination.title;
      if (this.entity.destination.parent) {
        title += `, ${this.entity.destination.parent.title}`;
      }

      return title;
    },
    entityLatitude() {
      return parseFloat(this.entity.latitude);
    },
    entityLongitude() {
      return parseFloat(this.entity.longitude);
    },
    mediaUrl() {
      return new ImageHandler().getFirstImgFromMedia(this.entity);
    },
    publishedAt() {
      return formatDate(this.entity.startPublishingAt);
    },
  },
  watch: {
    $route() {
      this.fetchEntityData();
    },
    showFindOnMap(val) {
      if (val) {
        this.$store.dispatch('disableScroll');
      } else {
        this.$store.dispatch('enableScroll');
      }
    },
  },
  created() {
    this.fetchEntityData();
  },
  methods: {
    fetchEntityData() {
      const variables = prepareVariablesForSingleEntityQuery(this.$route.params);

      this.entityDataFetching = true;

      this.$apollo
        .query({
          query: miniCultivistQuery,
          variables,
        })
        .then(({ data }) => {
          const entity = data?.miniCultivist || null;

          if (variables.onlyId && entity?.id) {
            this.$router.replace(
              redirectToSingleEntityRoute(this.$route.name, entity.id, variables.slug, { query: this.$route.query })
            );
            return;
          }

          this.entityDataFetching = false;
          this.entity = entity;

          if (this.entity) {
            this.isFavoriteState = this.entity.is_favorite_by_current_user;
            this.relatedRecords = this.entity.relatedRecords || [];
          }
        })
        .catch(() => {
          this.entityDataFetching = false;
        });
    },
    handleFavoriteAction() {
      if (!this.isFavoriteState) {
        this.$apollo
          .mutate({
            mutation: addToUserFavoritesMutation,
            variables: {
              entities: [{ id: this.entity.id, type: this.entity.__typename }],
            },
          })
          .then(({ data }) => {
            this.isFavoriteState = data.addToUserFavorites;
          })
          .catch((err) => {
            this.$toast.error(err);
          });

        return;
      }

      this.$apollo
        .mutate({
          mutation: deleteFromUserFavoritesMutation,
          variables: {
            entities: [{ id: this.entity.id, type: this.entity.__typename }],
          },
        })
        .then(({ data }) => {
          this.isFavoriteState = !data.deleteFromUserFavorites;
        })
        .catch((err) => {
          this.$toast.error(err);
        });
    },
    setDestination(destination) {
      this.$store.dispatch('destinationReview', destination);
    },
  },
};
</script>

<style lang="scss" scoped>
.current-item-wrapper {
  margin-top: 100px;
}

.header-without-media {
  margin-top: 65px;

  @media (min-width: 768px) {
    margin-top: 0;
  }
}

.stories_wrp {
  .data-details__top {
    margin-bottom: 50px;

    @media (min-width: 768px) {
      margin-bottom: 70px;
    }

    .data-details__cat {
      @media (min-width: 768px) {
        margin-bottom: 50px;
      }
    }
  }
}

.see-all {
  color: #363636;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 1.11px;
  text-decoration: underline;
  text-transform: uppercase;
  cursor: pointer;
}

.square {
  max-width: none;
  width: 100%;
  border-radius: 0;
  margin-bottom: 0;
}

.attend-modal {
  position: fixed;
  width: 100%;
  top: 0;
  height: 100vh;
  left: 0;
  z-index: 999999999;
  background-color: rgba(#000, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    max-width: 800px;
    padding: 30px;
    position: relative;
    background-color: white;
  }
}

.related {
  color: #363636;
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 2.14px;
  text-transform: uppercase;
}

.d-flex {
  span {
    &:last-of-type {
      margin-left: 5px;
    }
  }
}

h2.data-details__title {
  word-break: break-word;
}

.description {
  &-container {
    @media (min-width: 992px) {
      max-width: 755px;
    }
    @media (min-width: 1200px) {
      max-width: 755px;
    }
  }
}
</style>
